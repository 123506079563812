import { Button } from '@components/Button';
import { ButtonListContainer } from '@components/Button/ButtonListContainer';

interface Props {
  links: { slug: string; label: string }[];
}

export function PopularSearches({ links }: Props) {
  return (
    <section className="mx-auto my-3 max-w-[800px]">
      <h3 className="py-2 text-left text-[1.25rem] font-bold italic text-grey-dark dark:text-white">
        Popular Searches
      </h3>
      <ButtonListContainer>
        {links.map((c) => (
          <Button href={c.slug} key={c.slug} buttonStyle="text">
            {c.label}
          </Button>
        ))}
      </ButtonListContainer>
    </section>
  );
}
